import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';

import { LocalForage } from '../../../../utils/LocalForage';

import { PAGE_ITEMS_LIMIT } from '../../../../common/constants';

import {
  FetchCategoriesFilters,
  FetchCategoriesSort,
  FetchCategoriesPage,
  FetchCategoriesLimit,
  CategoriesType
} from '../../categoriesTypes';

import { fetchItems } from '../../../common/hooks/base/reactQuery/baseActions/fetchItems';

interface CategoriesResponse {
  categories: {
    nodes: CategoriesType;
    paginationInfo: {
      currentPage: number;
      nextPage: number;
      totalCount: number;
    };
  };
}

interface CategoriesOptions {
  cacheKey: string;
  query: string;
  initialFilters?: FetchCategoriesFilters;
  initialSort?: FetchCategoriesSort;
  initialPage?: FetchCategoriesPage;
  initialLimit?: FetchCategoriesLimit;
  options?: {
    cacheTime?: number;
  };
}

function useCategories({
  cacheKey,
  query,
  initialFilters = {},
  initialSort = ['CREATED_AT_DESC'],
  initialPage = 1,
  initialLimit = PAGE_ITEMS_LIMIT,
  options = {}
}: CategoriesOptions) {
  const [currentFilters, setCurrentFilters] =
    useState<FetchCategoriesFilters>(initialFilters);
  const [currentSort, setCurrentSort] =
    useState<FetchCategoriesSort>(initialSort);
  const [currentPage, setCurrentPage] =
    useState<FetchCategoriesPage>(initialPage);
  const [currentLimit, setCurrentLimit] =
    useState<FetchCategoriesLimit>(initialLimit);

  const { data: placeholderData, isFetched: placeholderDataFetched } =
    useQuery<CategoriesResponse | null>(`${cacheKey}-placeholder`, () =>
      LocalForage.getItem<CategoriesResponse>(cacheKey)
    );

  const currentParams = {
    filters: currentFilters,
    sort: currentSort,
    page: currentPage,
    limit: currentLimit
  };

  const { data, isFetched, isLoading, error, isPlaceholderData } =
    useQuery<CategoriesResponse>(
      [cacheKey, currentParams],
      () =>
        fetchItems({
          query,
          ...currentParams
        }),
      {
        enabled: placeholderDataFetched,
        cacheTime: options.cacheTime,
        onSuccess: (data) => {
          if (
            isEqual(currentFilters, initialFilters) &&
            isEqual(currentSort, initialSort) &&
            currentPage === initialPage &&
            currentLimit === initialLimit
          ) {
            LocalForage.setItem<CategoriesResponse>(cacheKey, data);
          }
        },
        placeholderData: () => {
          if (
            placeholderData &&
            isEqual(currentFilters, initialFilters) &&
            isEqual(currentSort, initialSort) &&
            currentPage === initialPage &&
            currentLimit === initialLimit
          ) {
            return placeholderData;
          }
        }
      }
    );

  const queryClient = useQueryClient();

  useEffect(() => {
    const params = {
      filters: currentFilters,
      sort: currentSort,
      page: currentPage + 1,
      limit: currentLimit
    };
    queryClient.prefetchQuery([cacheKey, params], () =>
      fetchItems({
        query,
        ...params
      })
    );
  }, [
    cacheKey,
    queryClient,
    query,
    currentFilters,
    currentSort,
    currentPage,
    currentLimit,
    data?.categories?.paginationInfo?.nextPage
  ]);

  return {
    categoriesData: data,
    categories: data?.categories?.nodes || [],
    categoriesError: error && error['message'],
    categoriesTotalCount:
      (isNumber(data?.categories?.paginationInfo?.totalCount)
        ? data?.categories?.paginationInfo?.totalCount
        : placeholderData?.categories?.paginationInfo?.totalCount) || 0,
    categoriesFetched: isFetched,
    categoriesLoading: isLoading,
    categoriesIsPlaceholderData: isPlaceholderData,
    categoriesFilters: currentFilters,
    categoriesSort: currentSort,
    categoriesPage: currentPage,
    categoriesLimit: currentLimit,
    filterCategories: useCallback(
      (nextFilters: FetchCategoriesFilters) => setCurrentFilters(nextFilters),
      [setCurrentFilters]
    ),
    clearCategoriesFilters: useCallback(
      () => setCurrentFilters({}),
      [setCurrentFilters]
    ),
    sortCategories: useCallback(
      (nextSort: FetchCategoriesSort) => setCurrentSort(nextSort),
      [setCurrentSort]
    ),
    paginateCategories: useCallback(
      (nextPage: FetchCategoriesPage) => setCurrentPage(nextPage),
      [setCurrentPage]
    ),
    limitCategories: useCallback(
      (nextLimit: FetchCategoriesLimit) => setCurrentLimit(nextLimit),
      [setCurrentLimit]
    ),
    prefetchCategories: useCallback(
      ({
        nextFilters,
        nextSort,
        nextPage,
        nextLimit
      }: {
        nextFilters?: FetchCategoriesFilters;
        nextSort?: FetchCategoriesSort;
        nextPage?: FetchCategoriesPage;
        nextLimit?: FetchCategoriesLimit;
      }) => {
        const params = {
          filters: nextFilters || currentFilters,
          sort: nextSort || currentSort,
          page: nextPage || currentPage,
          limit: nextLimit || currentLimit
        };
        queryClient.prefetchQuery([cacheKey, params], () =>
          fetchItems({
            query,
            ...params
          })
        );
      },
      [
        queryClient,
        query,
        cacheKey,
        currentFilters,
        currentSort,
        currentPage,
        currentLimit
      ]
    )
  };
}

export default useCategories;
